@import '~antd/dist/antd.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

ul {
  list-style-type: none;
}

.ant-form-item-label label{
  width: 120px;
  justify-content: flex-end;
}