.mainApp {
  width: 100%;
  height: 100%;;

  .logo {
    height: 28px;
    margin: 16px;
    overflow: hidden;

    img {
      height: 100%;
    }
  }

  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    .logo {
      height: 32px;
      margin: 16px;
      background: rgba(255, 255, 255, 0.3);
    }
  }
  
  .trigger:hover {
    color: #1890ff;
  }

  .layoutBackground {
    background: #fff;
  }
}