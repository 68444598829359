.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#e66465, #9198e5);

  .loginWrap {
    width: 704px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 9px 28px 8px rgba(3, 17, 46, 0.05),
      0px 6px 16px 0px rgba(3, 17, 46, 0.08),
      0px 3px 6px -4px rgba(3, 17, 46, 0.12);

    .loginHeader {
      width: 100%;
      height: 138px;
      border-bottom: 1px solid #DFE7EC;
      text-align: center;

      .sysLogo {
        display: inline-block;
        margin: 44px auto 16px;
      }

      .sysDesc {
        line-height: 22px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(3, 17, 46, 0.65);
      }
    }

    .loginHeaderRegist {
      .sysDesc {
        line-height: 38px;
        font-size: 26px;
        color: #307AFF;
        letter-spacing: 1px;
      }
    }

    .loginContent {
      width: 100%;
      height: 316px;
      padding: 0 168px 30px;

      .loginTabWrap {
        width: 368px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-around;

        .loginTab {
          width: 136px;
          line-height: 40px;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          color: rgba(3, 17, 46, 0.85);
          cursor: pointer;
          transition: color .3s;
        }
  
        .active {
          border-bottom: 2px solid #307AFF;
          color: #307AFF;
        }
      }

      .forgetPassword {
        color: #307AFF;
        text-align: right;
        cursor: pointer;
      }

      .codeTips {
        color: #999;
      }
    }
  }
}